import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";

interface WizardContainerProps {
  progressBarSteps: string[];
  progressBarStep: number;
  children: React.ReactNode;
}

export const WizardContainer = ({
  progressBarSteps,
  progressBarStep,
  children,
}: WizardContainerProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        marginTop: "16px",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Stepper activeStep={progressBarStep} sx={{ width: "100%" }}>
        {progressBarSteps.map((title) => (
          <Step key={title}>
            <StepLabel>{title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box
        sx={{
          width: "100%",
          marginTop: "36px",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
